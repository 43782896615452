* {
  font-family: Poppins, serif !important;
}
/*TODO change style for fonts*/

body {
  margin: 0;
  background-color: #F0F3FB;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.min-height-100-vh {
  min-height: 100vh;
}

.min-height-100-percent {
  min-height: 100%;
}

.height-100-percent {
  height: 100%;
}
